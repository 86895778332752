import * as React from "react"
import { graphql } from 'gatsby'

import CategoryTile from "../components/categoryTile"
import Layout from "../components/layout"

import * as Style from "../styles/categories.module.scss"

// markup
const CategoryPage = ({ data, pageContext }) => {
  var  t = function(a){return a.toUpperCase()};

  return (
    <Layout title={t('cagegoryPageTitle')}
      pageContext={pageContext}>
      <div class={Style.page}> 
        
        <p>locale: {pageContext.locale + 
        ' ' + t('categoryPageIntroduction')}</p>


        <div class={Style.categoryGrid}>
          {data.allAirtableCategories.nodes.map(category => (
            <CategoryTile category={category}
                          text={t('categoryTileArticles')}
                          locale='en' /> 
          ))}

        </div> 
      </div>
    </Layout>
  )
}
export const query = graphql`
  query CategoriesEN {  
    allAirtableCategories(
      sort: {fields: data___Name}
      filter: {data: {Locale: {eq: "en"}}}

  ) {
    nodes {
      data {
        Name
        Slug
        Markdown {
          childMdx {
            body
          }
        }
      }
      posts {
        frontmatter {
          title
          locale
          date(formatString: "D. MMMM YYYY", 
              locale: "en")
        }
        slug
        
      }
    }
  }
}
  
  
`

export default CategoryPage